import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { isNumeric } from "~/lib/utils";

// undefined: loading
// null: no organization
// number: organization ID
type OrganizationContextType = {
  organizationId: number | null | undefined;
  latestOrganizationId: number | null | undefined;
};

export const OrganizationContext = createContext<OrganizationContextType>({
  organizationId: undefined,
  latestOrganizationId: undefined,
});

export function OrganizationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();
  const [orgData, setOrgData] = useState<OrganizationContextType>({
    organizationId: undefined,
    latestOrganizationId: undefined,
  });

  useEffect(() => {
    const pathArray = pathname.split("/");
    let id = null;
    let storedOrganizationId = null;

    if (
      pathArray.length > 2 &&
      pathArray[1] === "community" &&
      isNumeric(pathArray[2])
    ) {
      id = Number(pathArray[2]);
    }

    // Store the organization ID in localStorage
    if (typeof window !== "undefined" && window.localStorage) {
      if (id) {
        window.localStorage.setItem("latestOrganizationId", String(id));
      }

      const data = window.localStorage.getItem("latestOrganizationId");
      storedOrganizationId = data && isNumeric(data) ? Number(data) : null;
    }

    setOrgData({
      organizationId: id,
      latestOrganizationId: id || storedOrganizationId,
    });
  }, [pathname]);

  const contextValue = useMemo(() => orgData, [orgData]);

  return (
    <OrganizationContext.Provider value={contextValue}>
      {children}
    </OrganizationContext.Provider>
  );
}

export function useOrganizationId() {
  return useContext(OrganizationContext);
}

export function clearLatestOrganizationId() {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.removeItem("latestOrganizationId");
  }
}
